.cardContainer {
  height: 90%;
}

.cardSubContainer {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.gridContainer {
  height: 90%;
}
