.container {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.subContainer {
  flex: 0.5;
  width: 90%;
  max-width: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.inputContainer {
  justify-content: center;
}

.optionsContainer {
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 30px;
}

.resetPasswordLink {
  color: #fff;
  align-self: flex-start;
  font-size: 16px;
}
