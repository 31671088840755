.info {
  font-size: 1rem;
}

.listContainer {
  padding-left: 0;
}

.listItem {
  padding-left: 0;
  line-height: 1.5;
  font-size: 1rem;
}
