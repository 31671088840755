.tableWrapper {
  border: 1px solid rgba(34, 36, 38, 0.1);
  padding-bottom: 0px;
  overflow: scroll;
}

.tableControlsWrapper {
  display: flex;
  margin-top: 5px;
  height: 40;
  justify-content: center;
}

.tableControlsButton {
  margin: 5px;
  width: 50px;
  height: 100%;
}

.tableControlsPageInfo {
  display: flex;
  height: 40px;
  margin: 0 5px;
  align-items: center;
  justify-content: center;
}
