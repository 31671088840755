.container {
    overflow: scroll;
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
    -ms-overflow-style: none; 
    scrollbar-width: none;  
}

.container::-webkit-scrollbar {
    display: none;
}