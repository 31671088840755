.row {
  display: flex;
  border-bottom: 1px solid rgba(34, 36, 38, 0.1);
  background-color: #fff;
}

.headerCellStyle {
  background-color: rgb(249, 250, 251);
  padding: 8px;
  border-left: 1px solid rgba(34, 36, 38, 0.1);
}

.headerCellNoBorderStyle {
  background-color: rgb(249, 250, 25);
  padding: 8px;
}

.headerText {
  font-weight: bold;
}
