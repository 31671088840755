.container {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  margin: 5px;
}

.subContainer {
  display: flex;
  justify-content: space-around;
  align-items: stretch;
}

.measure {
  text-align: center;
  flex: 1;
  margin: 0 7px;
}

.valueContainer {
  height: 30%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

.value {
  margin: 0;
  padding: 0;
}

.titleContainer {
  display: flex;
  align-items: center;
  height: 50%;
  justify-content: center;
}

.title {
  font-size: 1rem;
  margin: 5px;
}

.icon {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}