.cell {
  display: flex;
  padding: 8px 4px;
  border-right: 1px solid rgba(34, 36, 38, 0.1);
  width: 50px;
}

.buttonContainer {
  display: flex;
  margin-right: 6;
  justify-content: 'center';
  align-items: 'center';
  min-width: 45;
}

