.container {
  margin-bottom: 60px;
  height: 100vh; 
}

.loaderContainer {
  height: 100vh;
}

.miniLoaderContainer {
  margin-bottom: 50px;
  padding: 5px;
}
