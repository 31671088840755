.container {
  overflow: auto;
  white-space: nowrap;
  padding-left: 10px;
  padding-bottom: 5px;
}

.button {
  margin: 10px 5px 5px 0px;
  color: #333;
}

.icon {
  padding: 3px;
  margin-right: 5px;
  color: #333;
}

.text {
  margin-bottom: 5px;
  margin-right: 5px;
}
