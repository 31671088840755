.container {
  display: flex;
}

.iconContainer {
  display: flex;
  justify-content: flex-end;
  height: 25px;
}

.icon {
  padding: 2px 7px;
  color: #333;
}
