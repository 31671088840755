.searchbarContainer {
  width: 100%;
  position: fixed;
  top: 45px;
  z-index: 5;
  background-color: #efeff4;
  display: flex;
  flex-direction: row;
}

.searchbar {
  flex: 6;
}

.button {
  margin: 7px;
  flex: 1;
}