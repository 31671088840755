.navbarTitle {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
}

.icon {
  margin-left: 15px;
}

.card {
  width: 70%;
  margin: 10px;
  min-width: 250px;
}
