.container {
    height: 100vh;
    background-color: #221b44;
  }

  .subContainer {
    flex: 1;
    width: 90%;
    max-width: 300px;
  }

  .input {
    width: 100%;
  }

  .logo {
    width: 100%;
  }

  .button {
    width: 100%;
    background-color: #f035e0 !important;
  }