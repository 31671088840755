.flexColumn {
  display: flex;
  flex-direction: column;
}

.card {
  padding: 10px;
  margin: 10px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  border: 0.5px solid #ddd;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}
