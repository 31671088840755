/* SIDEBAR */

.my-drawer {
  position: relative;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}
.my-drawer .am-drawer-sidebar {
  background-color: #fff;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}
.my-drawer .am-drawer-sidebar .am-list {
  width: 300px;
  padding: 0;
}

/* BOOTOM TAB BAR */
.am-tab-bar {
  position: fixed !important;
  bottom: 0 !important;
  width: 100%;
  height: 50px !important;
  z-index: 5;
}

/* SHOWLIST CARD */
.am-grid
  .am-flexbox
  .am-flexbox-item.am-grid-item-active
  .am-grid-item-content {
  background-color: transparent !important;
}

/* NAVIGATION BAR */
.navigationBar {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 99;
  border-bottom: 0.5px solid #ccc;
}

/* NAVBAR TITLE */
.am-navbar-title {
  width: 60%;
}

.navigationBar,
.navigationBar i {
  color: #333;
}

.navigationBar {
  background-color: #f6f7f2 !important;
}

/* PICKER TEXT */
.am-picker-popup-header-right,
.am-picker-popup-header-left {
  color: #333 !important;
}

/* APPLIED FILTERS BUTTON */
.am-button.am-button-ghost.am-button-small.am-button-inline::before {
  border-color: #333;
  width: 190%;
}

/*SEARCBAR CLEAR ICON*/
.am-search-clear.am-search-clear-show {
  top: 7px !important;
  right: 5px !important;
}

/*Searchbar*/
.am-search-synthetic-ph-container {
  display: none;
}

.am-search-value {
  padding: 0 10px !important;
}

/************************UTILITIES****************************/
.center-element {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.vh-100 {
  height: 100vh;
}

.h-p100 {
  height: 100%;
}

.p0 {
  padding: 0;
}

.pt-45 {
  padding-top: 45px;
}

.pl-10 {
  padding-left: 10px;
}

.w-p100 {
  width: 100%;
}

.ta-center {
  text-align: center;
}

.fw-300 {
  font-weight: 300;
}

.mt-0 {
  margin-top: 0;
}

.fs-1rem {
  font-size: 1rem;
}
